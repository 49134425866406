import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Czech Republic shares most of it's mountain ranges with other countries
as for example Germany. The Erzgebirge is a Mittlegebirge with lots of
forest and trails.`}</p>
    <br />
    <Link className="text-black" to="/destination/czechrepublic/klinovec" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/klinovec_6.jpg" mdxType="DesktopRightImage" />
        <h2>{`Klinovec`}</h2>
        <h4>{`Trail Park with endless trails`}</h4>
        <p>{`Close to the border to Germany, the endless flowtrails wind down from the
Klinovec mountain. But don't back off to early, it also offers a gnarly DH
and an awesome Enduro track.`}</p>
        <PhoneImage src="destinations/klinovec_6.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      